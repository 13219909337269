import React, { useState } from "react";
import LoginForm from "./LoginForm";

const Login = ({ auth }) => {
  const [error, setError] = useState(null);
  const signIn = (password) => {
    auth
      .signInWithEmailAndPassword("admin@studentsrealestateclub.ch", password)
      .catch((reason) => {
        setError(reason.message);
      });
  };
  return (
    <section className="signed-out">
      <LoginForm
        authenticate={signIn}
        hasError={error != null}
        error={error}
        resetError={() => setError(null)}
      />
    </section>
  );
};

export default Login;
