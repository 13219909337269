import React, { useState } from "react";
import { Card, TextField, Button, Snackbar } from "@material-ui/core";
import "./LoginForm.css";

const LoginForm = ({ authenticate, resetError, hasError, error }) => {
  const [password, setPassword] = useState("");

  return (
    <div className="login-area">
      <Card className="login-form">
        <h1>Geschützter Bereich</h1>
        <Snackbar
          open={hasError}
          autoHideDuration={6000}
          onClose={resetError}
          message={error}
        />
        <div className="login-inputs">
          <TextField
            label="Benutzername"
            fullWidth
            variant="outlined"
            disabled
            value="Administrator"
          />
          <TextField
            label="Kennwort"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            onKeyUp={({ keyCode }) => {
              keyCode === 13 && authenticate(password);
            }}
          />
        </div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => authenticate(password)}
        >
          Anmelden
        </Button>
      </Card>
    </div>
  );
};

export default LoginForm;
