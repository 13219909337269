import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import SearchIcon from "@material-ui/icons/Search";

const columns = [
  { field: "event", headerName: "Event", width: 500 },
  { field: "user", headerName: "E-Mail", width: 440 },
];

const removingDuplicates = (events) => {
  const registered = {};
  return events.filter((event) => {
    if (!registered.hasOwnProperty(event.user)) {
      registered[event.user] = { [event.event]: true };
      return true;
    }
    if (!registered[event.user].hasOwnProperty(event.event)) {
      registered[event.user][event.event] = true;
      return true;
    }
    return false;
  });
};

const transformData = (events) =>
  removingDuplicates(events).map((event) => ({
    ...event,
  }));

const filterWithSearch = (query, events) =>
  events.filter((event) => {
    if (!query || query === "") return true;

    if (
      event.user &&
      event.user.toLowerCase().indexOf(query.toLowerCase()) >= 0
    )
      return true;
    if (
      event.event &&
      event.event.toLowerCase().indexOf(query.toLowerCase()) >= 0
    )
      return true;

    return false;
  });

const exportAsCSV = (arr) => {
  const keys = {
    user: "E-Mail",
  };

  const headers = Object.values(keys).join(",");
  const csv = arr
    .map((item) =>
      Object.keys(keys)
        .map((key) => item[key] || "")
        .join(",")
    )
    .join("\n");

  const csvContent = "data:text/csv;charset=utf-8," + headers + "\n" + csv;
  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "registrations.csv");
  document.body.appendChild(link);

  link.click();
};

const Registrations = ({ firestore }) => {
  const usersRef = firestore.collection("registrations");
  const query = usersRef;

  const [events] = useCollectionData(query, { idField: "id" });
  const [searchQuery, setSearchQuery] = useState("");

  const [dialogMessage, setDialogMessage] = useState(null);

  const organizedEvents = {};
  if (events) {
    events.forEach((event) => {
      if (!organizedEvents.hasOwnProperty(event.event)) {
        organizedEvents[event.event] = [];
      }
      organizedEvents[event.event].push(event);
    });
  }

  const [selectedEvent, setSelectedEvent] = useState("");

  return (
    <div className="data-area" style={{ padding: "18px 22px" }}>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
      >
        <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogMessage(null)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="data-area-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl>
          <InputLabel id="registrations-select-label">
            Event auswählen
          </InputLabel>
          <Select
            labelId="registrations-select-label"
            id="registrations-select"
            value={selectedEvent}
            label="Event auswählen"
            style={{ minWidth: 200 }}
            onChange={({ target }) => setSelectedEvent(target.value)}
          >
            {Object.keys(organizedEvents).map((event) => (
              <MenuItem value={event} key={event}>
                {event}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="data-area-spacer" style={{ flexGrow: "1" }}></div>
        <div className="data-area-actions">
          <Button
            onClick={() =>
              exportAsCSV(
                filterWithSearch(selectedEvent, transformData(events))
              )
            }
            disabled={selectedEvent === ""}
            style={{ marginRight: "22px" }}
          >
            Liste exportieren
          </Button>
        </div>
        <TextField
          placeholder="Nach Benutzer suchen"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={({ target }) => setSearchQuery(target.value)}
        />
      </div>
      <div style={{ height: "74vh", marginTop: "20px" }}>
        <DataGrid
          rows={
            events && selectedEvent !== ""
              ? filterWithSearch(
                  selectedEvent,
                  filterWithSearch(searchQuery, transformData(events))
                )
              : []
          }
          loading={!events}
          pageSize={25}
          columns={columns}
          variant="dense"
        />
      </div>
    </div>
  );
};

export default Registrations;
