import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import request from "request";
import { DataGrid } from "@material-ui/data-grid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import SearchIcon from "@material-ui/icons/Search";

const columns = [
  { field: "membershipActive", headerName: "Member", width: 90 },
  { field: "gender", headerName: "Herr", width: 70 },
  { field: "firstName", headerName: "Vorname", width: 140 },
  { field: "lastName", headerName: "Nachname", width: 140 },
  { field: "email", headerName: "E-Mail", width: 300 },
  { field: "mobile", headerName: "Telefon", width: 150 },
  { field: "studyDirection", headerName: "Studienrichtung", width: 190 },
  { field: "studySemester", headerName: "Semester", width: 130 },
];

const transformData = (users) =>
  users.map((user) => ({
    ...user,
    membershipActive: user.membershipActive === true ? "Member" : "",
  }));

const filterWithSearch = (query, users) =>
  users.filter((user) => {
    if (!query || query === "") return true;

    if (
      user.firstName &&
      user.firstName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    )
      return true;
    if (
      user.lastName &&
      user.lastName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    )
      return true;
    if (
      user.firstName &&
      user.lastName &&
      [user.firstName.toLowerCase() + user.lastName.toLowerCase()]
        .join(" ")
        .indexOf(query.toLowerCase()) >= 0
    )
      return true;
    if (
      user.email &&
      user.email.toLowerCase().indexOf(query.toLowerCase()) >= 0
    )
      return true;

    return false;
  });

let currentSelection = [];

const grantMembership = (rows, setDialogMessage) => {
  if (rows.length === 0) {
    setDialogMessage("Es sind keine Benutzer ausgewählt.");
    return;
  }
  let completedRows = 0;
  rows.forEach((row) => {
    console.log("Granting membership for " + row.email);
    request(
      `https://us-central1-srec-hsg.cloudfunctions.net/grantMembership?user=${row.email}`,
      (error, response, body) => {
        console.log(body);
        if (error) {
          setDialogMessage(
            `Ein Fehler beim Freigeben der Mitgliedschaft für ${[
              row.firstName,
              row.lastName,
            ].join(" ")}.`
          );
          return;
        }
        completedRows++;
        if (completedRows === rows.length) {
          setDialogMessage(
            `Mitgliedschaft für ${completedRows} Benutzer erfolgreich freigegeben.`
          );
        }
      }
    );
  });
};

const exportAsCSV = (arr) => {
  const keys = {
    gender: "Anrede",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    mobile: "Telefon",
    membershipActive: "Member",
    studyDirection: "Studienrichtung",
    studySemester: "Semester",
  };

  const headers = Object.values(keys).join(",");
  const csv = arr
    .map((item) =>
      Object.keys(keys)
        .map((key) => item[key] || "")
        .join(",")
    )
    .join("\n");

  const csvContent = "data:text/csv;charset=utf-8," + headers + "\n" + csv;
  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "users.csv");
  document.body.appendChild(link);

  link.click();
};

const Users = ({ firestore }) => {
  const usersRef = firestore.collection("users");
  const query = usersRef;

  const [users] = useCollectionData(query, { idField: "id" });
  const [searchQuery, setSearchQuery] = useState("");

  const [dialogMessage, setDialogMessage] = useState(null);

  return (
    <div className="data-area" style={{ padding: "18px 22px" }}>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
      >
        <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogMessage(null)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="data-area-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <TextField
          placeholder="Suche"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={({ target }) => setSearchQuery(target.value)}
        />
        <div className="data-area-actions">
          <Button
            onClick={() => exportAsCSV(transformData(users))}
            style={{ marginRight: "12px" }}
          >
            Alle exportieren
          </Button>
          <Button
            variant="contained"
            onClick={() => grantMembership(currentSelection, setDialogMessage)}
          >
            Membership freischalten
          </Button>
        </div>
      </div>
      <div style={{ height: "74vh", marginTop: "20px" }}>
        <DataGrid
          rows={
            users ? filterWithSearch(searchQuery, transformData(users)) : []
          }
          loading={!users}
          pageSize={25}
          columns={columns}
          checkboxSelection
          variant="dense"
          onSelectionChange={({ rows }) => (currentSelection = rows)}
        />
      </div>
    </div>
  );
};

export default Users;
