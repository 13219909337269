import React, { useState } from "react";
import ResponsiveDrawer from "./ResponsiveDrawer";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import Users from "./Users";
import Registrations from "./Registrations";

const tabs = {
  users: {
    key: "users",
    title: "Benutzerkonten",
    icon: <PeopleOutlinedIcon />,
  },
  registrations: {
    key: "registrations",
    title: "Registrationen",
    icon: <EventAvailableOutlinedIcon />,
  },
};

const Dashboard = ({ auth, firestore }) => {
  const [currentTab, setCurrentTab] = useState("users");
  let content = null;

  switch (currentTab) {
    case tabs.users.key:
      content = <Users firestore={firestore} />;
      break;
    case tabs.registrations.key:
      content = <Registrations firestore={firestore} />;
      break;
    default:
      break;
  }
  return (
    <ResponsiveDrawer
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      tabs={Object.values(tabs)}
      title={tabs[currentTab].title}
      logout={() => {
        auth.signOut();
        document.location.reload();
      }}
      content={content}
    />
  );
};

export default Dashboard;
