import React from "react";
import "./App.css";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

import firebaseConfig from "./FirebaseConfig";
import Login from "./Login";
import Dashboard from "./Dashboard";

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="App">
      {user ? (
        <Dashboard auth={auth} firestore={firestore} />
      ) : (
        <Login auth={auth} />
      )}
    </div>
  );
}

export default App;
