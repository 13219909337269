const firebaseConfig = {
  apiKey: "AIzaSyD4dicTvhTdvVtPRkZWalyNR5BOYHgir1s",
  authDomain: "srec-hsg.firebaseapp.com",
  databaseURL: "https://srec-hsg.firebaseio.com",
  projectId: "srec-hsg",
  storageBucket: "srec-hsg.appspot.com",
  messagingSenderId: "416424995771",
  appId: "1:416424995771:web:e877fd1cb051a81e31f29e",
};

export default firebaseConfig;
